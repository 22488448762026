// Media Device Breakpoints
$large-mobile         : 500px; // 480px
$tablet               : 767px; // 768px
$desktop-small        : 990px;
$desktop              : 1040px; // 1039px
$desktop1200       : 1200px;
$desktop1300       : 1300px;
$desktop1400       : 1400px;
$desktop1500       : 1500px;
$desktop1600       : 1600px;

// Breakpoints
@mixin breakpoint($breakpoint, $media: screen) {
    @if $breakpoint == large-mobile {
        @media only #{$media} and (max-width: $large-mobile) {
            @content;
        }
    }
    @if $breakpoint == tablet {
        @media only #{$media} and (max-width: $tablet) {
            @content;
        }
    }
    @if $breakpoint == desktop {
        @media only #{$media} and (max-width: $desktop) {
            @content;
        }
    }
    @if $breakpoint == desktop-small {
        @media only #{$media} and (max-width: $desktop-small) {
            @content;
        }
    }
    @if $breakpoint == desktop1200 {
        @media only #{$media} and (max-width: $desktop1200) {
            @content;
        }
    }
    @if $breakpoint == desktop1300 {
        @media only #{$media} and (max-width: $desktop1300) {
            @content;
        }
    }
    @if $breakpoint == desktop1400 {
        @media only #{$media} and (max-width: $desktop1400) {
            @content;
        }
    }
    @if $breakpoint == desktop1500 {
        @media only #{$media} and (max-width: $desktop1500) {
            @content;
        }
    }
    @if $breakpoint == desktop1600 {
        @media only #{$media} and (max-width: $desktop1600) {
            @content;
        }
    }
}
