@font-face {
    font-family: 'MontserratSemiBold';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
    url('../fonts/Montserrat-Light.woff') format('woff'),
    url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

$font-semibold: 'MontserratSemiBold';
$font-regular: 'MontserratRegular';
$font-light: 'MontserratLight';
$font-bold: 'MontserratBold';

$purple: #13213C;
$purple2: #182542;
$orange: #FBA311;
$white: #fff;
$white2: #e5e5e5;
$grey: #545E71;
$grey2: #828996;
$grey-light: #EFEFEF;
$grey-light2: #E5E5E5;

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';

//@import "~flag-icon-css/css/flag-icon.css";
@import "~toastr";
@import "app-breakpoints";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}


* {
    color: $purple;
    font-family: $font-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
}

a {
    color: $color2;
    &:hover {
        text-decoration: none;
    }
}

html {
    height: 100%;
}

.container {
    max-width: 1676px;
    padding-left: 30px;
    padding-right: 30px;
}

.btn-box {
    display: block;
    border-radius: 30px;
    padding: 0 20px;
    background-color: $orange;
    color: $white !important;
    line-height: 44px;
    @include transition(0.4s);
    &.grey {
        background-color: $grey;
    }
    &.purple {
        background-color: $purple;
        &:hover {
            background-color: #3F4A60;
        }
    }
    &:hover {
        background-color: $purple;
        text-decoration: none;
    }
    &.small {
        line-height: 32px;
    }
    &.big {
        line-height: 60px;
        padding: 0 55px;
        border: none;
        font-family: $font-bold;
        font-size: 16px;
        text-transform: uppercase;
        @include breakpoint(desktop1500) {
            font-size: 14px;
            line-height: 50px;
            padding: 0 40px;
        }
    }
    .spinner-border {
        border-color: $white;
        border-right-color: transparent;
    }
}

body {
    min-height: 100%;
    background-color: $white;
    font-size: 14px;
    margin: 0;
    @include breakpoint(desktop-small) {
        padding-top: 70px;
    }
    #app {
        background-color: $grey-light;
        min-height: 100vh;
    }

    &.authorized {
        background-color: $grey-light !important;
    }

    .spacer {
        display: block;
        height: 10px;
    }

    .b-skeleton-animate-wave {
        &::after {
            @media (prefers-reduced-motion: reduce) {
                background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent) !important;
                animation: b-skeleton-animate-wave 1.75s linear infinite !important;
            }
        }
    }

    .b-table {
        tbody {
            tr {
                td {
                    padding-left: 0px !important;
                }
            }
        }
    }

    div[data-route=write], div[data-route=sworn] {
        .select2-container .select2-search--inline .select2-search__field {
            margin-top: auto;
        }
    }

    .select2-container--default {
        .select2-selection--single .select2-selection__rendered {
            font-family: $font-light;
            font-size: 26px;
            line-height: 34px;
            color: $grey;
            @include breakpoint(desktop1500) {
                font-size: 21px;
            }
            @include breakpoint(desktop-small) {
                font-size: 18px;
            }
        }

        .select2-selection--single {
            height: 35px !important;
            border: none;
            border-bottom: 1px solid $grey;
            border-radius: 0;
            .select2-selection__arrow {
                height: 30px !important;
            }

        }

        #select2-source_lang-results {
            li:first-child {
                display: none !important;
            }
        }

        .select2-selection--multiple {
            min-height: 40px !important;

            li {
                height: 27px !important;
                padding-top: 6px !important;
                padding-right: 7px !important;
            }

            ul.select2-selection__rendered {
                padding-top: 3px;
            }

            textarea.select2-search__field {
                font-family: $font-regular !important;
                position: absolute;
                top: 6px;
            }

            ::placeholder {
                color: #000;
                opacity: 1;
            }

            .select2-selection__choice {
                background-color: #005aff !important;
                border: none !important;

                &, .select2-selection__choice__remove {
                    color: #FFF !important;
                }
            }

            .select2-selection__choice__remove {
                border: none !important;
                left: 5px;
                top: 6px;

                &:hover, &:focus {
                    background-color: #005aff !important;
                }
            }
        }
    }

    .summary {
        .card-body {
            ul {
                margin-bottom: 9px;
            }
        }
    }

    header {
        background-color: $white;
        position: relative;
        z-index: 9;
        @include breakpoint(desktop-small) {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    .vertical-center {
        min-height: 100vh;
        display: flex;
        align-items: center;
    }

    .no-padding {
        padding: 0px !important;
    }

    .table thead th {
        border: none;
    }

    h1, h2, h3, h4, .button {
        font-family: $font-bold;
    }

    label {
        font-family: $font-semibold;
        font-weight: normal;
        margin: 0;
    }

    .clearfix {
        &:after {
            clear: both;
            content: "";
            display: block;
        }
    }


    .lingy-checkbox {
        width: 100%;
        position: relative;
        box-shadow: none !important;

        input {
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            margin: 0;
        }

        &-info {
            color: #000;
            font-size: 16px;
            line-height: 1.4;
            padding-left: 30px;

            a {
                color: $color2;
            }
        }

        .help-block {
            padding-top: 15px;
        }
    }
}

#order-rating-box {
    .stars {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            li {
                font-size: 24px;
                margin-right: 10px;
                cursor: pointer;
                &.active {
                    color: $color2;
                }
            }
        }
    }
}

.button {
    padding: 19px 32px;
    text-transform: uppercase;
    display: inline-block;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    outline: none;
    line-height: 1;
    background-image: none;
    border: 2px;
    user-select: none;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &.disabled {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: .65;
    }
}

a.disabled {
    pointer-events: none;
}

.button--primary {
    background-color: $color2;
    color: $white;
    border-radius: 28px;
    border: 2px solid $color2;

    &:focus {
        color: $white;
    }
}

.button--secondary {
    background-color: transparent;
    color: $color2;
    border-radius: 28px;
    border: 2px solid $color2;
    box-sizing: content-box;
    padding: 19px 30px;
}

.button--order {
    padding: 19px 30px;
    background-color: $orange;
    border-color: $orange;
}

.button--settings {
    padding: 10px 32px;
}

.package-option-headline {
    display: block;
    height: 21px;
}
.package-option-description {
    padding: 0 5px;
}

a.button--order:hover{
    color: $orange;
}


.top-navigation {


    .navbar-nav .nav-item a {
        position: relative;
    }


    .navbar {
        background-color: $white;
        min-height: auto;
        padding: 0px;

        .navbar-brand {
            min-height: auto;
            padding: 13px 0;
            position: relative;
            > img {
                height: 44px;
            }
        }

        .navbar-nav {
            @include breakpoint(desktop-small) {
                display: none;
            }
            > li {
                padding: 0 20px;
                @include breakpoint(desktop1400) {
                    padding: 0 15px;
                }
                @include breakpoint(desktop) {
                    padding: 0 5px;
                }
                &:hover, &.active {
                    > a {
                        color: $orange;
                    }
                }

                > a {
                    font-family: $font-semibold;
                    color: $purple;
                    height: auto;
                    font-size: 16px;
                    overflow: hidden;
                    @include transition(0.4s);
                    @include breakpoint(desktop1400) {
                        font-size: 14px;
                        padding-top: 0;
                        padding-bottom: 0;
                        line-height: 44px;
                    }
                    @include breakpoint(desktop) {
                        font-size: 12px;
                    }
                    &.router-link-active {
                        color: $orange;
                    }
                    &.btn-box {
                        padding: 0 20px;
                        &.router-link-active {
                            color: $white;
                        }
                    }
                }

                .dropdown-menu {
                    margin: 0;
                    padding: 0;
                    a {
                        font-family: $font-bold;
                        color: $grey;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 14px;
                        padding: 15px 0 !important;
                        @include transition(0.4s);
                        text-align: center !important;
                        @include breakpoint(desktop) {
                            font-size: 12px;
                        }
                        &:hover {
                            color: $white !important;
                            background-color: $purple;
                        }
                    }
                }
                &.languages {
                    span {
                        font-family: $font-semibold;
                        color: $purple;
                        font-size: 16px;
                        text-transform: uppercase;
                        @include breakpoint(desktop1400) {
                            font-size: 14px;
                        }
                        @include breakpoint(desktop) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .wrapper {
        &.wrapper-content {
            padding-top: 40px;
        }
    }
    [data-route="write"],[data-route="package"],[data-route="sworn"],[data-route="proofreading"]{
        .form-group {
            label {
                padding-bottom: 5px;
            }
        }
        hr {
            margin-bottom: 0;
        }
    }

    .slide-fade-enter-active {
        transition: all 1s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    [data-route=top-up-summary], [data-route=order-summary-order-hash], [data-route=order-summary-cart-hash] {
        .custom-control-label {
            padding: 4px 17px
        }
    }
    [data-route=top-up] {
        h2 {
            font-size: 21px;
        }
    }

    [data-route=order-summary-order-hash], [data-route=order-summary-cart-hash] {
        .billing-address {
            .form-group {
                margin-bottom: 10px !important;

                label {
                    padding: 16px 20px !important;
                }
            }
        }
    }
}

[data-route=order-summary-cart-hash] {
    #order-summary-payment-methods {
        .payment-method {
            display: inline-block;
            width: 33.3%;
            padding-right: 15px;
            &.active {
                >div {
                    border: 2px solid $color1;
                }
            }
            >div {
                border: 2px solid $grey-light;
                cursor: pointer;
                height: 150px;
            }
        }
    }
}
[data-route="password-reset"], [data-route="password-forgot"] {
    #content {
        main {
            display: flex;
            justify-content: center;
            .contentbox {
                width: 50%;
                .passwordbox {
                    .iconbox {
                        margin-bottom: 25px;
                    }
                    .form-submit {
                        margin-top: 30px;
                    }
                    .link-back {
                        font-famiy: $font-semibold;
                        text-transform: uppercase;
                        color: $purple;
                        @include transition(0.4s);
                        margin-top: 20px;
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
}

.card {
    @include border-radius(6px);

    .card-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: $color1;
        color: $white;
        font-size: 17px;
        font-family: $font-bold;
        padding-left: 2.25rem;

        &.icon {
            padding-top: 9px;
            padding-bottom: 9px;

            a {
                float: left;
            }

            span {
                padding-top: 5px;
                padding-left: 10px;
            }
        }

        &:not(.icon) {
            span {
                padding-top: 2px;
            }

        }

        span {
            font-family: $font-bold;
            display: flex;
        }

        div {
            font-family: $font-bold;
        }
    }

    .card-body {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }
}

#summary-price-gross-field-without-coupon, #summary-price-net-field-without-coupon {
    text-decoration: line-through;
}
#summary-price-gross-field-coupon, #summary-price-net-field-coupon {
    color: $orange;
}


.form-group {


    label {
        width: 100%;
        color: $grey;
        padding: 16px 20px;
        text-align: left;
        font-size: 13px;

        &.error {
            color: $error;
            margin: 0;
        }
    }

    .button {
        font-size: 13px;
    }

    &.error {
        label {
            color: $error;
        }
        input[type="text"] {
            border-color: $error;
        }
    }
}


.table {
    border-bottom: 1px solid #e7eaec;

    > thead {
        > tr {
            > th {
                color: $grey;
                font-family: $font-semibold;
                font-weight: normal;
            }
        }
    }
}


a.dropdown-item:hover {
    background-color: transparent;
    color: #005aff !important;
}

#content {
    padding: 100px 30px;
    @include breakpoint(desktop1500) {
        padding: 60px 30px;
    }
    @include breakpoint(large-mobile) {
        padding: 40px 30px;
    }
    .contentbox {
        background-color: $white;
        padding: 70px 140px;
        @include breakpoint(desktop1500) {
            padding: 70px;
        }
        @include breakpoint(desktop-small) {
            padding: 40px 30px;
        }
    }
}

.order-form {
    #content_from {
        height: 140px;
        line-height: 22px;
        font-size: 12px;
        font-weight: normal;
    }

    textarea::placeholder {
        font-size: 14px;
        color: #999;
    }

    #dropzone-dnd-area {
        display: none;

        .area {
            position: absolute;
            top: 0;
            z-index: 9999;
            width: 100%;
            .d-block {
                width: 100%;
            }
        }

        .text {
            border: 2px dashed #6c757d;
            background-color: #FFF;
            color: #000;
        }
    }

    .dropzone-dnd-backdrop {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.3;
        z-index: 999
    }

    .dropzone-previews input {
        font-size: small;
    }

    .input-group.dz-error {
        .input-group-text {
            cursor: pointer;
            background-color: #dc3545;
            border: 1px solid #dc3545;
            color: #FFF;
        }
    }

    .has-error .select2-selection {
        border-color: rgb(185, 74, 72) !important;
    }

    #add_file {
        font-size: 14px;
    }

    &.disabled {
        button[type=submit] {
            display: none;
        }

        .hide {
            display: block !important;
        }

        .form-group[readonly] {
            .select2-selection {
                background-color: #e9ecef;
            }
        }
    }

    .dz-complete {
        .fa-spinner {
            &.fa-spin {
                animation: none !important;
            }

            &:before {
                content: "\F1F8" !important;
            }
        }
    }

    .text-black {
        color: #000000;
    }
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 23px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

h3,
h4,
h5 {
    font-weight: 600;
}

.table > thead > tr > th {
    padding-left: 0px;
}

hr {
    margin-top: 3px;
}

.pagination {
    font-size: 12px !important;
}

.invoice {
    .row.dates {
        margin-top: 45px;
    }
    .invoice_num {
        color: $color2;
    }
    .table-responsive {
        overflow-x: auto !important;
    }
    .table > tbody > tr > td, .invoice .table > tfoot > tr > td {
        border-top: none !important;
        padding-left: 0px;
    }
    .table {
        border-bottom: none !important;
    }
}

.order-details {
    color: #555;

    hr {
        margin-top: 4px !important;
    }

    h3 {
        text-transform: uppercase;
    }

    .text-muted {
        color: #a5a8b8 !important;
    }
}

.card-body {
    h3 {
        color: $color1;
        font-size: 14px;
        text-align: center;
        font-weight: 300;
        display: block;
        margin: 15px auto;
    }

    .border {
        border: 2px solid $color1 !important;
        border-radius: 15px;
        padding: 15px;

        &:hover {
            border: 4px solid $color1 !important;
            box-sizing: border-box;
            padding: 13px;
        }
    }

    .custom-padding {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 15px;
    }
}
form{
    legend{
        text-align: left;
    }
}

[data-route=invoice] {
    .invoice_num {
        text-align: left;
    }
}

#tidio-chat {
    iframe {
        @media screen and (min-width: 900px) {
            bottom: 80px !important;
        }
    }
}

.submit-error-msg {
    color: $error;
}
#order-packages-cta-box {
    padding: 0 30px;
}

#cookies-box {
    background-color: $color1;
    padding: 12px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 101;

    .box {
        position: relative;
        a {
            color: #fff;
        }
        p {
            width: calc(100% - 100px);
            float: left;
            font-size: 13px;
            color: #fff;
            a {
                font-size: 13px;
                color: #fff;
                text-decoration: underline;
            }
        }

        button {
            float: right;
        }

        span {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: -10px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
        }
    }
}

@media (max-width: 480px) {

    #cookies-box {
        .box {
            text-align: center;

            p {
                width: 100%;
                text-align: center;
                font-size: 11px;

                a {
                    font-size: 11px;
                }
            }

            button {
                float: none;
            }
        }
    }
}

#summary-order-paymentsbox {
    &>div {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(large-mobile) {
            display: block;
        }
        .field-item {
            display: block;
            overflow: hidden;
            width: 25%;
            padding-right: 12px;
            box-sizing: border-box;
            @media (max-width: 650px) {
                width: 33.3%;
            }
            @include breakpoint(large-mobile) {
                width: 100%;
                padding: 0;
                margin-bottom: 12px;
            }
            .custom-control {
                padding-left: 0;
                label {
                    border-radius: 10px;
                    background-color: $grey-light3;
                    border: 2px solid $grey-light3;
                    cursor: pointer;
                    padding: 0;
                    overflow: hidden;
                    span {
                        display: block;
                        &.icon {
                            height: 150px;
                            padding-top: 35px;
                            background-color: $white;
                            img {
                                display: block;
                                margin: 0 auto;
                                max-width: 100%;
                            }
                        }
                        &.name {
                            color: $color1;
                            text-align: center;
                            padding: 12px 0;
                        }
                    }
                }
            }
            &.active {
                .custom-control {
                    label {
                        border: 2px solid $color1;
                        span.name {
                            background-color: $color1;
                            color: $white;
                            font-weight: 800;
                        }
                    }
                }
            }
            &:last-child {
                @include breakpoint(large-mobile) {
                    margin: 0;
                }
            }
        }
    }
}

body div.thulium-chat-wrapper {
    z-index: 200 !important;
}

#tm_formbox {
    width: 700px;
    margin: 0 auto;
    padding-top: 50px;
    .bannerbox {
        margin-bottom: 40px;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .spinner-border {
        border-color: #ff6e64;
        border-right-color: transparent;
        margin-left: 15px;
    }
    fieldset {
        padding-bottom: 20px;
        margin-bottom: 20px;
        &.bb {
            border-bottom: 1px solid $grey-light2;
        }
        &.submit {
            padding-bottom: 0;
        }
        .title {
            font-size: 14px;
            color: $grey;
            margin-bottom: 10px;
        }
        .radio {
            display: flex;
            margin-bottom: 10px;
            label {
                margin-top: -2px;
                margin-left: 10px;
                font-size: 14px;
                cursor: pointer;
            }
        }
        .filename {
            font-size: 14px;
            color: $grey;
            margin-top: 10px;
        }
    }
    .filelink {
        a {
            color: #28a745;
        }
    }
    .errorinfo {
        color: $error;
    }
    .card {
        &.bottombox {
            margin-top: 50px;
            padding: 30px;
            * {
                color: $color1;
            }
            h3 {
                font-size: 14px;
            }
            ol {
                margin: 0;
            }
        }
    }
}

[data-route=mtdemo] {
   #content {
       margin-top: 0 !important;
   }
}

.paymentbox-listbox {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    .title {
        color: #a5a8b8;
        font-family: $font-semibold;
        font-size: inherit;
        margin-bottom: 15px;
        @include breakpoint(large-mobile) {
            font-size: 14px;
        }
    }
    .items-colbox {
        display: flex;
        @media screen and (max-width: 500px) {
            display: block;
        }
        .item-colbox {
            &:first-child {
                width: 40%;
                @media screen and (max-width: 500px) {
                    width: 100%;
                    max-width: 230px;
                }
            }
            &:nth-child(2) {
                width: 60%;
                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }
            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

#paypobox-sidebar {
    background-color: $white;
    position: fixed;
    z-index: 4;
    right: 20px;
    bottom: 150px;
    padding: 60px 20px 40px 20px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    @media screen and (max-width: 1500px) {
        bottom: 120px;
        padding: 40px 12px 20px 12px;
    }
    @media screen and (max-width: 1400px) {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    @media screen and (max-width: 1040px) {
        scale: 0.8;
        right: -15px;
    }
    @media screen and (max-width: 700px) {
        scale: 0.7;
        right: -20px;
    }
    @media screen and (max-width: 500px) {
        scale: 0.6;
        right: -27px;
    }

    &:before {
        content: "";
        display: block;
        background: url(../img/paypobox-bg1.png) no-repeat center;
        background-size: contain;
        width: 65px;
        height: 42px;
        position: absolute;
        top: 12px;
        right: 12px;
        @media screen and (max-width: 1500px) {
            width: 45px;
            height: 32px;
            top: 5px;
            right: 5px;
        }
    }

    &:after {
        content: "";
        display: block;
        background: url(../img/paypobox-bg2.png) no-repeat center;
        background-size: contain;
        width: 28px;
        height: 22px;
        position: absolute;
        bottom: 12px;
        left: 12px;
        @media screen and (max-width: 1500px) {
            width: 20px;
            height: 15px;
            bottom: 5px;
            left: 5px;
        }
    }

    .logo {
        background: url(../img/logo-paypobox.png) no-repeat center;
        background-size: cover;
        width: 120px;
        height: 32px;
        margin: 0 auto;
        @media screen and (max-width: 1500px) {
            width: 90px;
            height: 24px;
        }
    }

    .textbox {
        margin-top: 10px;
        @media screen and (max-width: 1500px) {
            margin-top: 6px;
        }

        div {
            &:nth-child(1) {
                font-family: $font-light;
                font-size: 14px;
                color: $color1;
                text-align: center;
            }

            &:nth-child(2) {
                font-family: $font-semibold;
                font-size: 16px;
                line-height: 1;
                color: #26c7b5;
                text-align: center;
                @media screen and (max-width: 1500px) {
                    font-size: 14px;
                }
            }
        }
    }
}
#sworn-inpostbox {
    width: 100%;
    padding-top: 20px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
        height: 500px;
    }
}

.tracking-link {
    color: $color1;
    text-decoration: underline;
}

.packages {

    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    ul {
        min-height: 170px;
        list-style-type: none;
    }

    .text-muted {
        color: #a5a8b8 !important;
    }

    .subheadline {
        min-height: 60px;
        font-size: 13px;
    }

    .variant {
        h3 {
            font-size: 18px;
        }

        &.active {
            box-shadow: .0px 0px 56px 1px #dfe0e6;

            label.button {
                cursor: not-allowed;
                background-color: #fff;
                color: $color2;
            }
        }
    }
}

.card-body {
    padding-bottom: 0;
    h3 {
        color: $color1;
        font-size: 14px;
        text-align: center;
        font-weight: 300;
        display: block;
        margin: 15px auto;
    }

    .border {
        border: 2px solid $color1 !important;
        border-radius: 15px;
        padding: 15px;

        &:hover {
            border: 4px solid $color1 !important;
            box-sizing: border-box;
            padding: 13px;
        }
    }

    .custom-padding {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 15px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
        animation: 2s infinite linear normal b-icon-animation-spin !important;
        -webkit-animation: 2s infinite linear normal b-icon-animation-spin !important;
    }
}

@media (max-width: 750px) {
    ul {
        &.navbar-nav {
            li {
                a {
                    &.nav-link {
                        text-align: right;
                    }
                }
            }
        }
    }
}

nav {
    a {
        &.logout {
            cursor: pointer;
        }
    }

    .router-link-active {

        .bottom-bar {
            display: block !important;
        }
    }
}


footer {
    background-color: $purple;
    padding: 52px 0 130px 0;
    position: relative;
    overflow: hidden;
    @include breakpoint(desktop-small) {
        padding: 50px 0;
    }
    .bg {
        position: absolute;
        top: 0;
        left: -180px;
        @include breakpoint(desktop-small) {
            top: auto;
            bottom: 0;
        }
        @include breakpoint(large-mobile) {
            width: 1100px;
        }
        svg {
            @include breakpoint(large-mobile) {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .contentbox {
        position: relative;
        display: flex;
        justify-content: space-between;
        @include breakpoint(desktop-small) {
            display: block;
        }
        .copyright {
            color: $white;
        }
        .footer-logo {
            display: block;
            @include breakpoint(desktop1500) {
                width: 200px;
            }
            img {
                display: block;
                max-width: 100%;
                @include breakpoint(desktop-small) {
                    height: 44px;
                }
            }
        }
        .items {
            width: 70%;
            @include breakpoint(desktop-small) {
                width: 100%;
                margin-top: 30px;
            }
            >ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                @include breakpoint(desktop-small) {
                    flex-wrap: wrap;
                }
                >li {
                    width: 25%;
                    padding-right: 35px;
                    box-sizing: border-box;
                    @include breakpoint(desktop-small) {
                        width: 50%;
                        margin-bottom: 30px;
                    }
                    h3 {
                        color: $white2;
                        font-size: 30px;
                        font-weight: normal;
                        font-family: $font-regular;
                        position: relative;
                        padding-bottom: 25px;
                        margin-bottom: 35px;
                        @include breakpoint(desktop1500) {
                            font-size: 24px;
                            padding-bottom: 15px;
                            margin-bottom: 20px;
                        }
                        @include breakpoint(desktop-small) {
                            margin-bottom: 15px;
                            line-height: 1;
                        }
                        @include breakpoint(large-mobile) {
                            font-size: 21px;
                        }
                        &:after {
                            content: "";
                            display: block;
                            background: url(../img/icon-footer-label.svg) no-repeat center;
                            width: 98px;
                            height: 6px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                    .txtbox {
                        .txt {
                            font-size: 16px;
                            color: $white;
                            @include breakpoint(desktop1500) {
                                font-size: 14px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 12px;
                            }
                            .data-contact {
                                a {
                                    display: block;
                                    color: $white;
                                    font-size: 16px;
                                    padding-left: 30px;
                                    @include breakpoint(desktop1500) {
                                        font-size: 14px;
                                    }
                                    @include breakpoint(large-mobile) {
                                        font-size: 12px;
                                    }
                                    @include breakpoint(desktop-small) {
                                        display: block;
                                        margin-bottom: 4px;
                                    }
                                    &.phone {
                                        background: url(../img/icon-phone.svg) no-repeat left center;
                                        background-size: 19px;
                                    }
                                    &.mail {
                                        background: url(../img/icon-mail.svg) no-repeat left center;
                                        background-size: 19px;
                                    }
                                }
                            }
                        }
                        .smbox {
                            display: flex;
                            margin-top: 30px;
                            a {
                                display: block;
                                margin-right: 20px;
                            }
                        }
                        .subtitle {
                            margin-top: 30px;
                            font-family: $font-semibold;
                            color: $white;
                            font-size: 16px;
                            display: block;
                            @include breakpoint(desktop1500) {
                                font-size: 14px;
                                margin-top: 24px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 12px;
                            }
                        }
                    }
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        li {
                            a {
                                display: block;
                                color: $white;
                                font-size: 16px;
                                @include breakpoint(desktop1500) {
                                    font-size: 14px;
                                }
                                @include breakpoint(large-mobile) {
                                    font-size: 12px;
                                }
                                &.login {
                                    margin-top: 30px;
                                    font-family: $font-semibold;
                                }
                            }
                        }
                    }
                    &:nth-child(3), &:nth-child(4) {
                        @include breakpoint(desktop-small) {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.brand_lingy {
    footer {
        padding: 40px 0;
    }
}

.headlinebox {
    color: $purple;
    font-family: $font-light;
    font-size: 43px;
    line-height: 1.2;
    font-weight: normal;
    margin-bottom: 40px;
    @include breakpoint(desktop1500) {
        font-size: 32px;
        margin-bottom: 30px;
    }
    @include breakpoint(large-mobile) {
        font-size: 24px;
    }
    span {
        font-family: $font-bold;
        @include breakpoint(large-mobile) {
            font-size: 24px;
        }
    }
}

.b-pagination-pills {
    .page-item {
        .page-link {
            width: 40px;
            line-height: 40px;
            padding: 0;
            @include border-radius(50%);
            font-family: $font-semibold;
            color: $grey2;
            font-size: 16px;
            border-color: $grey2;
            margin-left: 20px;
            text-align: center;
            @include breakpoint(desktop1300) {
                margin-left: 12px;
                width: 30px;
                line-height: 30px;
                font-size: 14px;
            }
        }
        &.disabled {
            .page-link {
                color: $grey-light2;
                border-color: $grey-light2;
            }
        }
        &.active {
            .page-link {
                color: $white;
            }
        }
    }
}

#tablet-listbox {
    border-bottom-color: $grey;
    @include breakpoint(desktop-small) {
        width: 150%;
    }
    th {
        * {
            font-family: $font-semibold;
            font-size: 16px;
            color: $purple2;
            @include breakpoint(desktop1500) {
                font-size: 14px;
            }
        }
    }
    tbody {
        tr {
            td {
                border-top-color: $grey;
                font-family: $font-light;
                font-size: 16px;
                color: $purple2;
                @include breakpoint(desktop1500) {
                    font-size: 14px;
                }
                * {
                    font-family: $font-light;
                    font-size: 16px;
                    color: $purple2;
                    @include breakpoint(desktop1500) {
                        font-size: 14px;
                    }
                }
                &:last-child {
                    span {
                        @include breakpoint(desktop1400) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

[data-route='order-list'], [data-route='invoice-list'] {
    .contentbox {
        .table-contentbox {
            min-height: 350px;
        }
        .bottombox {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
        }
    }
}

[data-route='order-created'], [data-route='password-verification'], [data-route="register-info"] {
    #content {
        .contentbox {
            padding: 0;
            display: flex;
            justify-content: space-between;
            .leftbox {
                width: 50%;
                padding: 40px 0 70px 140px;
                box-sizing: border-box;
                @include breakpoint(desktop) {
                    padding-left: 40px;
                }
                @include breakpoint(desktop-small) {
                    width: 100%;
                }
                .infobox {
                    margin-top: 45px;
                    .headlinebox {
                        padding-right: 100px;
                        margin-bottom: 40px;
                    }
                    .txtbox {
                        padding-right: 140px;
                        margin-bottom: 50px;
                        @include breakpoint(desktop) {
                            padding-right: 60px;
                        }
                        p {
                            font-family: $font-semibold;
                            font-size: 24px;
                            color: $purple;
                            @include breakpoint(desktop1400) {
                                font-size: 21px;
                                line-height: 1.3;
                            }
                        }
                    }
                    .btn-bottom {
                        display: flex;
                        a.btn-box {
                            font-family: $font-bold;
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                    }
                    .btn-linkbox {
                        display: flex;
                        a.btn-box {
                            font-family: $font-bold;
                            font-size: 16px;
                            line-height: 1.1;
                            text-transform: uppercase;
                            &.btn-contact {
                                width: 240px;
                                padding: 10px 75px 10px 24px;
                                box-sizing: border-box;
                                text-align: center;
                                position: relative;
                                &:after {
                                    content: "";
                                    background: url(../img/icon-free-consultation.svg) no-repeat center right 10px;
                                    display: block;
                                    width: 38px;
                                    height: 21px;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 15px;
                                }
                            }
                        }
                    }
                    .btn-linkbox2 {
                        display: flex;
                    }
                }
            }
            .rightbox {
                width: 50%;
                padding: 60px 70px 40px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                @include breakpoint(desktop) {
                    padding-right: 40px;
                }
                @include breakpoint(desktop-small) {
                    display: none;
                }
                .imgbox {
                    width: 600px;
                    height: 600px;
                    background-color: $orange;
                    @include border-radius(50%);
                    position: relative;
                    align-self: flex-end;
                    @include breakpoint(desktop1300) {
                        width: 500px;
                        height: 500px;
                    }
                    @include breakpoint(desktop) {
                        width: 400px;
                        height: 400px;
                    }
                    img {
                        display: block;
                        width: 120%;
                        position: absolute;
                        top: -80px;
                        right: -50px;
                        @include breakpoint(desktop1300) {
                            top: -60px;
                        }
                        @include breakpoint(desktop) {
                            top: -40px;
                        }
                    }
                }
            }
        }
    }
}

[data-route='proofreading'] {
    #content {
        .contentbox.orderbox {
            .order-contentbox .leftbox {
                .itembox.item-langsbox {
                    .item-valuebox .box {
                        &:nth-child(1) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

[data-route='package']{
    overflow: hidden;
    form {
        width: 100%;
    }
    .bottom-morebox {
        margin-top: 50px;
        .headlinebox {
            text-align: center;
            margin: 0;
            @include breakpoint(large-mobile) {
                font-size: 24px;
            }
            span {
                display: block;
                font-family: $font-bold;
                font-size: 43px;
                @include breakpoint(desktop1500) {
                    font-size: 32px;
                }
                @include breakpoint(large-mobile) {
                    font-size: 24px;
                }
            }
        }
        .btn-showmore {
            width: 60px;
            height: 60px;
            @include border-radius(50%);
            background-color: $orange;
            margin: 12px auto 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            @include transition(0.4s);
            position: relative;
            z-index: 2;
            &:hover {
                background-color: $purple;
            }
        }
    }
    #packages-detailsbox {
        background-color: $grey-light2;
        margin-top: -30px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 1000px;
            background-color: $grey-light2;
            position: absolute;
            top: 0;
            right: 100%;
            height: 100%;
        }
        &:after {
            content: "";
            display: block;
            width: 1000px;
            background-color: $grey-light2;
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
        }
        &.active {
            .content {
                visibility: visible;
            }
        }
        .sign-ok {
            background: url(../img/icon-tick.png) no-repeat center;
            background-size: cover;
            width: 24px;
            height: 24px;
        }
        .sign-cross {
            background: url(../img/icon-cross.png) no-repeat center;
            background-size: cover;
            width: 24px;
            height: 24px;
        }
        .content {
            @include transition(0.7s);
            height: 1px;
            visibility: hidden;
            overflow: hidden;
            @include breakpoint(desktop-small) {
                overflow: auto;
            }
            .content-box {
                display: flex;
                width: calc(100% + 44px);
                margin-left: -22px;
                padding: 90px 0;
                @include breakpoint(desktop-small) {
                    width: 150%;
                }
                @include breakpoint(tablet) {
                    margin-left: 0;
                }
            }
        }
        table {
            width: 100%;
            height: 1px;
            thead {
                tr {
                    td {
                        width: 20%;
                        padding-left: 22px;
                        padding-right: 22px;
                        @include breakpoint(tablet) {
                            padding-left: 7px;
                            padding-right: 7px;
                        }
                        div {
                            font-family: $font-semibold;
                            font-size: 26px;
                            line-height: 1;
                            padding-bottom: 12px;
                            border-bottom: 2px solid #13213C;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    height: 100%;
                    td {
                        height: 100%;
                        width: 20%;
                        padding-left: 22px;
                        padding-right: 22px;
                        @include transition(0.4s);
                        @include breakpoint(tablet) {
                            padding-left: 7px;
                            padding-right: 7px;
                        }
                        >div {
                            height: 100%;
                            border-bottom: 1px solid #13213C;
                            font-family: $font-light;
                            font-size: 16px;
                            line-height: 1.1;
                            padding: 10px 0;
                            display: flex;
                            align-items: center;
                            @include breakpoint(desktop1500) {
                                font-size: 14px;
                            }
                        }
                        &:first-child {
                            >div {
                                display: block;
                                font-family: $font-semibold;
                                span {
                                    font-family: $font-light;
                                }
                            }
                        }
                    }
                    &:hover {
                        td {
                            background-color: $grey-light;
                        }
                    }
                }
            }
        }
        .itembox {
            width: 20%;
            padding: 0 22px;
            .top-title {
                font-family: $font-semibold;
                font-size: 26px;
                line-height: 1;
                padding-bottom: 12px;
                @include breakpoint(desktop1500) {
                    font-size: 21px;
                }
            }
            .item-txt {
                border-bottom: 1px solid $purple;
                font-family: $font-light;
                font-size: 16px;
                line-height: 1.1;
                padding: 10px 0;
                min-height: 42px;
                box-sizing: border-box;
                @include breakpoint(desktop1500) {
                    font-size: 14px;
                }
                &:nth-child(2) {
                    border-top: 2px solid $purple;
                }
            }
            &:first-child {
                padding-top: 38px;
                .item-txt {
                    font-family: $font-semibold;
                    span {
                        font-family: $font-light;
                    }
                    &:first-child {
                        border-top: 2px solid $purple;
                    }
                    &:nth-child(2) {
                        border-top: none;
                    }
                }
            }
        }
    }
}

.order-contentbox {
    &#order-summary {
        .form-group {
            margin-bottom: 30px;
            @include breakpoint(desktop-small) {
                margin-bottom: 20px;
            }
        }
        .rightbox {
            > div {
                display: block !important;
            }
            .titlebox {
                font-family: $font-bold;
                font-size: 43px;
                color: $purple;
                margin-bottom: 30px;
                @include breakpoint(desktop1300) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }
            .items {
                .top {
                    font-family: $font-light;
                    font-size: 26px;
                    line-height: 1;
                    color: $purple;
                    padding-bottom: 14px;
                    @include breakpoint(desktop1300) {
                        font-size: 21px;
                    }
                    span {
                        font-family: $font-semibold;
                        font-size: 26px;
                        color: $purple;
                        @include breakpoint(desktop1300) {
                            font-size: 21px;
                        }
                    }
                }
                .item {
                    border-top: 1px solid $purple;
                    padding: 8px 0;
                    display: flex;
                    justify-content: space-between;
                    &:last-child {
                        border-bottom: 1px solid $purple;
                    }
                    .label {
                        width: 50%;
                        font-family: $font-semibold;
                        font-size: 20px;
                        line-height: 1.2;
                        color: $purple;
                        @include breakpoint(desktop1300) {
                            font-size: 18px;
                        }
                    }
                    .val {
                        width: 50%;
                        font-family: $font-light;
                        font-size: 20px;
                        line-height: 1.2;
                        color: $purple;
                        @include breakpoint(desktop1300) {
                            font-size: 18px;
                        }
                        * {
                            font-family: $font-light;
                            font-size: 20px;
                            line-height: 1.2;
                            color: $purple;
                            @include breakpoint(desktop1300) {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .summary-pricingbox {
                background-color: #3F4A60;
                margin-top: 100px;
                padding: 20px;
                @include breakpoint(desktop1300) {
                    margin-top: 40px;
                }
                .item {
                    display: flex;
                    justify-content: space-between;
                    padding: 6px 0;
                    border-bottom: 2px solid $white;
                    @include breakpoint(desktop1300) {
                        border-bottom: 1px solid $white;
                        padding: 10px 0;
                    }
                    .name {
                        font-family: $font-semibold;
                        font-size: 20px;
                        color: $white;
                        @include breakpoint(desktop1300) {
                            font-size: 18px;
                            padding-right: 15px;
                            line-height: 1.1;
                        }
                    }
                    .val {
                        font-family: $font-light;
                        font-size: 20px;
                        color: $white;
                        text-align: right;
                        @include breakpoint(desktop1300) {
                            font-size: 18px;
                            line-height: 1.1;
                        }
                    }
                }
                .coupon-field {
                    margin-top: 20px;
                    input {
                        font-family: $font-light;
                        line-height: 40px;
                        font-size: 20px;
                        border-radius: 0;
                        @include breakpoint(desktop1300) {
                            font-size: 18px;
                        }
                    }
                }
                .btn-submitbox {
                    margin-top: 30px;
                    display: flex;
                    justify-content: center;
                }
                .errorsbox {
                    margin-top: 15px;
                    * {
                        color: $white;
                        text-align: center;
                    }
                }
            }
        }
    }
    .icon-loading {
        margin: 20px 0;
    }
}

#order-packagesbox {
    display: flex;
    margin-left: -22px;
    width: calc(100% + 44px);
    @include breakpoint(desktop-small) {
        display: flex;
        width: 100%;
        margin: 0;
    }
    .itembox {
        width: 25%;
        padding: 30px 22px 0 22px;
        box-sizing: border-box;
        @include breakpoint(desktop1500) {
            padding-left: 12px;
            padding-right: 12px;
        }
        @include breakpoint(desktop-small) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-bottom: 30px;
        }
        .box {
            background-color: $white;
            padding: 30px 40px;
            height: 100%;
            @include breakpoint(desktop1500) {
                padding: 24px 30px;
            }
            @include breakpoint(desktop1300) {
                padding: 24px 20px;
            }
            * {
                color: $purple;
            }
            .top {
                display: none;
            }
            .titlebox {
                font-family: $font-bold;
                font-size: 43px;
                line-height: 1;
                padding-bottom: 25px;
                border-bottom: 2px solid $purple;
                margin-bottom: 25px;
                @include breakpoint(desktop1500) {
                    font-size: 32px;
                    padding-bottom: 15px;
                }
                @include breakpoint(desktop1300) {
                    font-size: 24px;
                }
                @include breakpoint(large-mobile) {
                    font-size: 21px;
                }
            }
            .textbox {
                position: relative;
                padding-bottom: 35px;
                border-bottom: 2px solid $purple;
                margin-bottom: 25px;
                @include breakpoint(desktop1500) {
                    padding-bottom: 30px;
                }
                @include breakpoint(desktop-small) {
                    padding-bottom: 0;
                }
                .txtbox {
                    padding-left: 60px;
                    padding-bottom: 50px;
                    line-height: 1.3;
                    position: relative;
                    @include breakpoint(desktop) {
                        padding-left: 50px;
                    }
                    @include breakpoint(desktop-small) {
                        padding-bottom: 30px;
                    }
                    strong {
                        font-family: $font-semibold;
                        display: block;
                    }
                    span {
                        display: block;
                        margin-top: 8px;
                    }
                    &:first-child {
                        padding-bottom: 30px;
                        &:before {
                            content: "";
                            display: block;
                            width: 30px;
                            height: 54px;
                            background: url(../img/icon-order-packages-1.svg) no-repeat center;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    &:last-child {
                        margin: 0;
                        &:before {
                            content: "";
                            display: block;
                            width: 31px;
                            height: 33px;
                            background: url(../img/icon-order-packages-3.svg) no-repeat center;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
            .pricebox {
                margin-bottom: 25px;
                @include breakpoint(desktop1500) {
                    margin-bottom: 20px;
                }
                .gross {
                    font-family: $font-semibold;
                    font-size: 28px;
                    line-height: 1.2;
                    text-align: center;
                }
                .net {
                    font-family: $font-light;
                    font-size: 21px;
                    line-height: 1.2;
                    text-align: center;
                }
            }
            .bottom {
                display: flex;
                justify-content: center;
                label {
                    cursor: pointer;
                    * {
                        color: $white;
                        font-family: $font-bold;
                        font-size: 16px;
                    }
                    input {
                        display: none;
                    }
                    &.btn-box {
                        width: 100%;
                        padding: 20px;
                        line-height: 1.2;
                        text-align: center;
                    }
                }
            }
        }
        &.active {
            padding-top: 0;
            .box {
                background-color: #3F4A60;
                padding-top: 0;
                * {
                    color: $white;
                }
                .top {
                    display: block;
                    color: $white;
                    text-transform: uppercase;
                    font-family: $font-semibold;
                    font-size: 20px;
                    padding-top: 12px;
                    line-height: 1;
                    margin-bottom: 28px;
                    @include breakpoint(desktop1500) {
                        font-size: 16px;
                        margin-bottom: 24px;
                    }
                    @include breakpoint(desktop1300) {
                        font-size: 14px;
                        margin-bottom: 20px;
                        padding-top: 20px;
                    }
                }
                .titlebox {
                    border-color: $white;
                }
                .textbox {
                    border-color: $white !important;
                }
            }
        }
        &:first-child {
            .box {
                .textbox {
                    .txtbox {
                        &:first-child {
                            padding-bottom: 100px;
                            @include breakpoint(desktop-small) {
                                padding-bottom: 30px;
                            }
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }
        &:nth-child(2) {
            .box {
                .textbox {
                    .txtbox {
                        &:first-child {
                            padding-bottom: 115px;
                            @include breakpoint(desktop-small) {
                                padding-bottom: 30px;
                            }
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }
        &:nth-child(3) {
            .box {
                .textbox {
                    .txtbox {
                        &:first-child {
                            &:after {
                                content: "";
                                display: block;
                                background: url(../img/icon-plus.svg) no-repeat center;
                                background-size: cover;
                                width: 18px;
                                height: 37px;
                                position: absolute;
                                bottom: -2px;
                                left: 60px;
                            }
                        }
                        &:nth-child(2) {
                            &:before {
                                content: "";
                                display: block;
                                width: 37px;
                                height: 40px;
                                background: url(../img/icon-order-packages-2.svg) no-repeat center;
                                background-size: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        &:nth-child(4) {
            .box {
                .textbox {
                    .txtbox {
                        &:first-child {
                            &:after {
                                content: "";
                                display: block;
                                background: url(../img/icon-plus.svg) no-repeat center;
                                background-size: cover;
                                width: 18px;
                                height: 37px;
                                position: absolute;
                                bottom: -2px;
                                left: 60px;
                            }
                        }
                        &:nth-child(2) {
                            &:before {
                                content: "";
                                display: block;
                                width: 37px;
                                height: 40px;
                                background: url(../img/icon-order-packages-4.svg) no-repeat center;
                                background-size: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-route="settings"] {
    #content {
        .contentbox {
            background: none;
            display: flex;
            padding: 0;
            justify-content: space-between;
            @include breakpoint(desktop) {
                display: block;
            }
            .leftbox {
                width: 35%;
                padding-right: 20px;
                @include breakpoint(desktop) {
                    width: 100%;
                    padding: 0;
                }
                .box {
                    height: 100%;
                    background-color: $white;
                    padding: 65px 0 65px 80px;
                    @include breakpoint(desktop) {
                        padding: 40px 30px;
                    }
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        li {
                            @include breakpoint(desktop) {
                                margin-bottom: 10px;
                            }
                            &:last-child {
                                margin: 0;
                                @include breakpoint(desktop) {
                                    margin: 0;
                                }
                            }
                            a {
                                display: block;
                                font-family: $font-light;
                                font-size: 22px;
                                line-height: 1.3;
                                color: $purple2;
                                @include transition(0.4s);
                                position: relative;
                                overflow: hidden;
                                padding-left: 70px;
                                padding-top: 35px;
                                padding-bottom: 35px;
                                @include breakpoint(desktop1500) {
                                    font-size: 18px;
                                }
                                @include breakpoint(desktop) {
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                }
                                @include breakpoint(large-mobile) {
                                    line-height: 1.2;
                                    padding: 15px 0 15px 50px;
                                    background-size: 30px !important;
                                    background-position: left center !important;
                                }
                                &:hover, &.active {
                                   font-family: $font-semibold;
                                }
                                &:after {
                                    content: '';
                                    display: block;
                                    width: 10px;
                                    height: 100%;
                                    background-color: $orange;
                                    position: absolute;
                                    top: 0;
                                    right: -10px;
                                    @include transition(0.4s);
                                    @include breakpoint(large-mobile) {
                                        width: 5px;
                                    }
                                }
                                &.active {
                                    &:after {
                                        right: 0;
                                    }
                                }
                            }
                            &:first-child {
                                a {
                                    background: url(../img/icon-settings.svg) no-repeat left center;
                                }
                            }
                            &:nth-child(2) {
                                a {
                                    background: url(../img/icon-invoice.svg) no-repeat left center;
                                }
                            }
                            &:nth-child(3) {
                                a {
                                    background: url(../img/icon-contact-data.svg) no-repeat left center;
                                }
                            }
                        }
                    }
                    .bottom {
                        width: calc(100% - 80px);
                        margin-top: 90px;
                        border-top: 2px solid $grey;
                        padding-top: 75px;
                        padding-left: 70px;
                        background: url(../img/icon-wallet.svg) no-repeat left top 75px;
                        @include breakpoint(desktop1500) {
                            margin-top: 50px;
                            padding-top: 40px;
                            background-position: left top 45px;
                        }
                        @include breakpoint(desktop-small) {
                            margin-top: 30px;
                            padding-top: 30px;
                            background-position: left top 35px;
                        }
                        @include breakpoint(large-mobile) {
                            width: 100%;
                            background-size: 30px;
                            padding-left: 50px;
                        }
                        .title {
                            font-family: $font-light;
                            font-size: 22px;
                            color: $purple2;
                            @include breakpoint(desktop1500) {
                                font-size: 18px;
                            }
                        }
                        .val {
                            font-family: $font-light;
                            font-size: 43px;
                            color: $purple2;
                            line-height: 1;
                            @include breakpoint(desktop1500) {
                                font-size: 32px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            .rightbox {
                width: 65%;
                padding-left: 20px;
                @include breakpoint(desktop) {
                    width: 100%;
                    padding: 0;
                }
                .box {
                    background-color: $white;
                    padding: 70px 140px;
                    @include breakpoint(desktop) {
                        padding: 40px 30px;
                    }
                    @include breakpoint(large-mobile) {
                        padding: 10px 30px 40px 30px;
                    }
                    .password-box {
                        margin-bottom: 30px;
                        >div {
                            display: flex;
                        }
                    }
                    .submitbox {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}

[data-route="top-up"] {
    #content {
        .contentbox {
            padding-bottom: 200px;
            @include breakpoint(desktop) {
                padding-bottom: 40px;
            }
            .topbox {
                display: flex;
                justify-content: space-between;
                margin-bottom: 60px;
                @include breakpoint(desktop) {
                    display: block;
                    margin-bottom: 20px;
                }
                .headlinebox {
                    width: 30%;
                    margin: 0;
                    @include breakpoint(desktop) {
                        width: 100%;
                    }
                }
                .txtbox {
                    width: 70%;
                    font-family: $font-regular;
                    font-size: 30px;
                    color: $purple;
                    line-height: 1.2;
                    padding-right: 150px;
                    @include breakpoint(desktop1500) {
                        font-size: 21px;
                    }
                    @include breakpoint(desktop) {
                        width: 100%;
                        padding: 0;
                        font-size: 18px;
                        margin-top: 15px;
                    }
                    @include breakpoint(large-mobile) {
                        font-size: 16px;
                    }
                }
            }
            .itemsbox {
                display: flex;
                width: calc(100% + 40px);
                margin-left: -20px;
                @include breakpoint(desktop) {
                    flex-wrap: wrap;
                }
                @include breakpoint(large-mobile) {
                    display: block;
                }
                .itembox {
                    width: 20%;
                    padding: 45px 20px 0 20px;
                    @include breakpoint(desktop1500) {
                        padding-top: 32px;
                    }
                    @include breakpoint(desktop) {
                        width: 50%;
                        padding-top: 30px;
                    }
                    @include breakpoint(large-mobile) {
                        width: 100%;
                    }
                    h2 {
                        display: flex;
                        justify-content: center;
                        padding: 25px 0 15px 0;
                        @include breakpoint(desktop) {
                            padding: 15px 0;
                            margin: 0;
                        }
                        @include breakpoint(large-mobile) {
                            padding: 12px 0;
                        }
                        .val {
                            font-family: $font-semibold;
                            font-size: 42px;
                            line-height: 1;
                            color: $white;
                            @include breakpoint(desktop1500) {
                                font-size: 32px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 24px;
                            }
                        }
                        .code {
                            font-family: $font-regular;
                            font-size: 26px;
                            color: $white;
                            line-height: 1;
                            padding: 14px 0 0 10px;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                                padding: 9px 0 0 6px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 18px;
                                padding: 4px 0 0 5px;
                            }
                        }
                    }
                    .bonus-container {
                        padding: 24px 0;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid $grey2;
                        margin-bottom: 24px;
                        @include breakpoint(desktop1500) {
                            padding: 20px 0;
                        }
                        @include breakpoint(desktop) {
                            padding: 15px 0;
                            min-height: auto;
                            margin-bottom: 15px;
                        }
                        @include breakpoint(large-mobile) {
                            padding: 12px 0;
                            margin-bottom: 12px;
                        }
                        span {
                            display: block;
                            font-family: $font-light;
                            font-size: 26px;
                            line-height: 1;
                            color: $purple;
                            text-transform: uppercase;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                            }
                            @include breakpoint(desktop) {
                                line-height: 1;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 18px;
                            }
                        }
                        h3 {
                            display: block;
                            font-family: $font-bold;
                            font-size: 26px;
                            line-height: 1;
                            color: $purple;
                            margin: 0;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                            }
                            @include breakpoint(desktop) {
                                line-height: 1;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 18px;
                            }
                        }
                    }
                    .btn-submitbox {
                        @include breakpoint(desktop) {
                            display: flex;
                            justify-content: center;
                        }
                        .btn-box {
                            line-height: 1.2;
                            padding-top: 12px;
                            padding-bottom: 12px;
                        }
                    }
                    &:nth-child(1) {
                        h2 {
                            background-color: #828995;
                        }
                    }
                    &:nth-child(2) {
                        h2 {
                            background-color: #6a7282;
                        }
                    }
                    &:nth-child(3) {
                        h2 {
                            background-color: #545e70;
                        }
                    }
                    &:nth-child(4) {
                        h2 {
                            background-color: #3f4a5f;
                        }
                    }
                    &:last-child {
                        padding-top: 0;
                        @include breakpoint(desktop) {
                            padding-top: 30px;
                        }
                        h2 {
                            padding: 0;
                            display: block;
                            font-family: $font-bold;
                            font-size: 26px;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 18px;
                            }
                        }
                        .bonus-container {
                            padding: 8px 0 0 0;
                            margin-bottom: 107px;
                            @include breakpoint(desktop1500) {
                                margin-bottom: 95px;
                            }
                            @include breakpoint(desktop) {
                                margin-bottom: 15px;
                            }
                            input[type="number"] {
                                border: none;
                                padding: 0 20px 0 0;
                                font-family: $font-light;
                                font-size: 43px;
                                color: $grey2;
                                @include breakpoint(desktop1500) {
                                    font-size: 32px;
                                }
                                @include breakpoint(large-mobile) {
                                    font-size: 21px;
                                }
                            }
                            .input-group-text {
                                border: none;
                                padding: 0;
                                line-height: 43px;
                                background: none;
                                font-family: $font-regular;
                                font-size: 30px;
                                color: $purple;
                                @include breakpoint(desktop1500) {
                                    font-size: 21px;
                                }
                                @include breakpoint(large-mobile) {
                                    font-size: 18px;
                                }
                            }
                        }
                        .btn-submitbox {
                            .btn-box {
                                &[disabled="disabled"] {
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-route="top-up-summary"] {
    .topbox {
        .headlinebox {
            margin: 0;
        }
    }
    .itemsbox {
        margin-top: 30px;
        .itembox {
            border-top: 1px solid $grey2;
            padding: 40px 0;
            display: flex;
            @include breakpoint(desktop-small) {
                padding: 20px 0;
                display: block;
            }
            .titlebox {
                width: 30%;
                box-sizing: border-box;
                padding-right: 50px;
                font-size: 30px;
                line-height: 1.2;
                font-family: $font-regular;
                color: $purple;
                @include breakpoint(desktop1500) {
                    font-size: 24px;
                    padding-right: 90px;
                }
                @include breakpoint(desktop-small) {
                    width: 100%;
                    font-size: 21px;
                    padding: 0;
                }
            }
            .val-box {
                width: 70%;
                @include breakpoint(desktop-small) {
                    width: 100%;
                    margin-top: 12px;
                }
            }
            &:nth-child(2) {
                .val-box {
                    display: flex;
                    justify-content: flex-start;
                    @include breakpoint(tablet) {
                        display: block;
                    }
                    >div {
                        .title {
                            font-family: $font-regular;
                            font-size: 26px;
                            color: $purple;
                            margin-bottom: 20px;
                            @include breakpoint(desktop1500) {
                                font-size: 21px;
                            }
                            @include breakpoint(desktop-small) {
                                font-size: 18px;
                            }
                            @include breakpoint(tablet) {
                                margin-bottom: 0;
                                line-height: 1;
                            }
                        }
                        .val {
                            display: flex;
                            div {
                                &:first-child {
                                    font-family: $font-bold;
                                    font-size: 43px;
                                    color: $purple;
                                    @include breakpoint(desktop1500) {
                                        font-size: 32px;
                                    }
                                    @include breakpoint(desktop-small) {
                                        font-size: 24px;
                                    }
                                }
                                &:nth-child(2) {
                                    font-family: $font-regular;
                                    font-size: 30px;
                                    color: $purple;
                                    padding: 12px 0 0 15px;
                                    @include breakpoint(desktop1500) {
                                        font-size: 24px;
                                        padding: 9px 0 0 10px;
                                    }
                                    @include breakpoint(desktop-small) {
                                        font-size: 21px;
                                        padding: 3px 0 0 5px;
                                    }
                                }
                            }
                        }
                        &:first-child {
                            margin-right: 90px;
                            @include breakpoint(tablet) {
                                margin-right: 0;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

[data-route="order"] {
    #content {
        .contentbox {
            display: flex;
            justify-content: space-between;
            @include breakpoint(desktop-small) {
                display: block;
            }
            .order-tablebox {
                .itembox {
                    display: flex;
                    padding: 15px 0;
                    border-bottom: 1px solid $grey;
                    @include breakpoint(large-mobile) {
                        display: block;
                        padding: 12px 0;
                    }
                    .title {
                        width: 40%;
                        font-family: $font-semibold;
                        font-size: 20px;
                        color: $purple;
                        @include breakpoint(desktop1500) {
                            font-size: 16px;
                        }
                        @include breakpoint(large-mobile) {
                            width: 100%;
                            line-height: 1;
                        }
                    }
                    .val {
                        width: 60%;
                        font-family: $font-light;
                        font-size: 20px;
                        color: $purple;
                        @include breakpoint(desktop1500) {
                            font-size: 16px;
                        }
                        @include breakpoint(large-mobile) {
                            width: 100%;
                        }
                        * {
                            font-family: $font-light;
                            font-size: 20px;
                            color: $purple;
                            @include breakpoint(desktop1500) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .filesbox {
                margin-top: 40px;
                .itembox {
                    display: flex;
                    margin-bottom: 40px;
                    @include breakpoint(large-mobile) {
                        display: block;
                    }
                    &:last-child {
                        margin: 0;
                    }
                    .icon {
                        background-color: $orange;
                        width: 60px;
                        height: 60px;
                        @include border-radius(50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include breakpoint(large-mobile) {
                            display: none;
                        }
                    }
                    .box {
                        width: calc(100% - 60px);
                        padding-left: 40px;
                        box-sizing: border-box;
                        @include breakpoint(large-mobile) {
                            width: 100%;
                            padding: 0;
                        }
                        .title {
                            font-family: $font-semibold;
                            font-size: 20px;
                            color: $purple;
                            line-height: 24px;
                            margin-bottom: 10px;
                            @include breakpoint(desktop1500) {
                                font-size: 16px;
                            }
                        }
                        .filebox {
                            margin-bottom: 12px;
                            &:last-child {
                                margin: 0;
                            }
                            span {
                                line-height: 32px;
                                @include breakpoint(large-mobile) {
                                    line-height: 1.2;
                                }
                            }
                            a {
                                cursor: pointer;
                            }
                            .float-right {
                                @include breakpoint(large-mobile) {
                                    float: none !important;
                                    margin-top: 10px;
                                    display: flex;
                                }
                            }
                        }

                    }
                }
            }
            .leftbox {
                width: calc(70% - 80px);
                @include breakpoint(desktop-small) {
                    width: 100%;
                }
            }
            .rightbox {
                width: calc(30% - 50px);
                @include breakpoint(desktop-small) {
                    width: 100%;
                }
                .headlinebox {
                    @include breakpoint(large-mobile) {
                        margin-bottom: 0;
                    }
                }
                .submitbox {
                    margin-top: 40px;
                    >div {
                        display: flex;
                        .btn-box {
                            line-height: 1.2;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            text-align: center;
                        }
                        &:nth-child(2) {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

[data-route="login"], [data-route="register"] {
    #content {
        .contentbox {
            display: flex;
            justify-content: space-between;
            padding: 0;
            @include breakpoint(desktop-small) {
                display: block;
            }
            >div {
                padding: 90px 0 100px 0;
                @include breakpoint(desktop1500) {
                    padding: 70px 0 90px;
                }
                .formbox {
                    width: 500px;
                    margin: 0 auto;
                    @include breakpoint(desktop1500) {
                        width: 100%;
                        padding: 0 60px;
                        box-sizing: border-box;
                    }
                    @include breakpoint(large-mobile) {
                        padding: 0 30px;
                    }
                    .headlinebox {
                        @include breakpoint(large-mobile) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .loginbox {
                width: 50%;
                background-color: $white;
                @include breakpoint(desktop-small) {
                    width: 100%;
                }
                @include breakpoint(large-mobile) {
                    padding: 40px 0;
                }
                .iconbox {
                    padding-top: 16px;
                    margin-bottom: 25px;
                    @include breakpoint(desktop1500) {
                        width: 40px;
                        margin-bottom: 20px;
                    }
                    @include breakpoint(large-mobile) {
                        padding: 0;
                        width: 32px;
                        margin-bottom: 15px;
                    }
                    svg {
                        max-width: 100%;
                        height: auto;
                    }
                }
                form {
                    fieldset.form-group {
                        &:nth-child(2) {
                            margin-bottom: 18px;
                        }
                    }
                    .bottom {
                        a {
                            color: $purple2;
                            font-size: 16px;
                            @include transition(0.4s);
                            @include breakpoint(large-mobile) {
                                font-size: 14px;
                            }
                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                    .form-submit {
                        margin-top: 5px;
                        display: flex;
                        justify-content: flex-end;
                        @include breakpoint(large-mobile) {
                            margin-top: 15px;
                        }
                    }
                }
            }
            .registerbox {
                width: 50%;
                background-color: $orange;
                @include breakpoint(desktop-small) {
                    width: 100%;
                }
                .iconbox {
                    margin-bottom: 25px;
                    @include breakpoint(desktop1500) {
                        width: 40px;
                        margin-bottom: 20px;
                    }
                    @include breakpoint(large-mobile) {
                        width: 32px;
                        margin-bottom: 15px;
                    }
                    img {
                        display: block;
                        max-width: 100%;
                    }
                }
                form {
                    .form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control:-moz-placeholder {
                        color: #3F4A60 !important;
                    }
                    .field-checkbox {
                        label {
                            padding: 0;
                        }
                    }
                    .submit-box {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}

[data-route="home"], [data-route="write"], [data-route="sworn"], [data-route="proofreading"] {
    #content {
        overflow: hidden;
        padding-top: 70px;
        padding-bottom: 70px;
        @include breakpoint(desktop1500) {
            padding: 60px 30px;
        }
        @include breakpoint(large-mobile) {
            padding: 50px 30px;
        }
    }
}

#select2-sendOriginal-results {
    .select2-results__option {
        position: relative;
        span {
            background-color: $orange;
            color: $white;
            display: inline-block;
            padding: 0 5px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 700;
            margin-left: 10px;
        }
    }
}

#content {
    .contentbox {
        position: relative;
        .numbox {
            font-family: $font-semibold;
            font-size: 600px;
            line-height: 0.7;
            color: #E5E5E5;
            position: absolute;
            top: -70px;
            right: -90px;
            z-index: 0;
            @include breakpoint(desktop1500) {
                font-size: 400px;
            }
            @include breakpoint(large-mobile) {
                font-size: 240px;
                right: -50px;
            }
            @include breakpoint(large-mobile) {
                top: -50px;
            }
        }
        &.orderbox {
            background: none;
            padding: 0;
            .topbox {
                display: flex;
                position: relative;
                z-index: 1;
                justify-content: space-between;
                padding-bottom: 30px;
                margin-bottom: 35px;
                border-bottom: 2px solid #0F1E36;
                @include breakpoint(desktop-small) {
                    flex-direction: column;
                    padding-bottom: 20px;
                    margin-bottom: 25px;
                }
                .titlebox {
                    font-family: $font-light;
                    font-size: 43px;
                    color: $purple;
                    @include breakpoint(desktop1500) {
                        font-size: 32px;
                    }
                    @include breakpoint(desktop-small) {
                        order: 2;
                        margin-top: 12px;
                    }
                    @include breakpoint(large-mobile) {
                        font-size: 24px;
                        line-height: 1.2;
                    }
                    span {
                        font-family: $font-bold;
                    }
                }
                .pagersbox {
                    @include breakpoint(desktop-small) {
                        order: 1;
                    }
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        li {
                            display: block;
                            width: 60px;
                            line-height: 56px;
                            box-sizing: border-box;
                            @include border-radius(50%);
                            border: 2px solid $grey2;
                            text-align: center;
                            font-family: $font-semibold;
                            font-size: 24px;
                            color: $grey2;
                            margin-left: 100px;
                            position: relative;
                            @include breakpoint(desktop1500) {
                                font-size: 18px;
                                width: 45px;
                                height: 45px;
                                line-height: 41px;
                                margin-left: 60px;
                            }
                            @include breakpoint(desktop-small) {
                                margin-left: 0;
                                margin-right: 50px;
                            }
                            @include breakpoint(large-mobile) {
                                font-size: 14px;
                            }
                            &:after {
                                content: '';
                                width: 100px;
                                height: 2px;
                                background-color: $grey2;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                right: calc(100% + 2px);
                                @include breakpoint(desktop1500) {
                                    width: 60px;
                                }
                                @include breakpoint(desktop-small) {
                                    width: 50px;
                                }
                            }
                            &:first-child {
                                &:after {
                                    content: none;
                                }
                            }
                            &.active {
                                background-color: $orange;
                                color: $white;
                                border-color: $orange;
                                a {
                                    color: $white;
                                }
                            }
                            &:nth-child(2), &:nth-child(3) {
                                &.active {
                                    &:after {
                                        background-color: $orange;
                                    }
                                }
                            }
                            a {
                                display: block;
                                font-family: $font-semibold;
                                color: $grey2;
                            }
                        }
                    }
                }
            }
            .order-contentbox {
                position: relative;
                display: flex;
                justify-content: space-between;
                @include breakpoint(desktop) {
                    display: block;
                }
                &#order-packagesbox {
                    @include breakpoint(desktop) {
                        display: flex;
                    }
                    @include breakpoint(desktop-small) {
                        display: block;
                    }
                }
                .leftbox {
                    width: 75%;
                    padding-right: 50px;
                    box-sizing: border-box;
                    @include breakpoint(desktop1500) {
                        width: 70%;
                        padding-right: 20px;
                    }
                    @include breakpoint(desktop1400) {
                        width: 75%;
                    }
                    @include breakpoint(desktop) {
                        width: 100%;
                        padding: 0;
                    }
                    .itembox {
                        width: 100%;
                        display: flex;
                        padding-bottom: 60px;
                        @include breakpoint(desktop-small) {
                            display: block;
                            padding-bottom: 40px;
                        }
                        @include breakpoint(tablet) {
                            padding-bottom: 30px;
                        }
                        &.typebox {
                            padding-bottom: 50px;
                        }
                        .item-name {
                            width: 40%;
                            font-family: $font-regular;
                            font-size: 30px;
                            line-height: 1;
                            @include breakpoint(desktop1500) {
                                width: 35%;
                                font-size: 24px;
                            }
                            @include breakpoint(desktop-small) {
                                width: 100%;
                                margin-bottom: 15px;
                            }
                            @include breakpoint(desktop-small) {
                                font-size: 21px;
                            }
                        }
                        .item-valuebox {
                            width: 60%;
                            @include breakpoint(desktop) {
                                width: 65%;
                            }
                            @include breakpoint(desktop-small) {
                                width: 100%;
                            }
                            .upselling-box {
                                .items {
                                    display: flex;
                                    justify-content: space-between;
                                    flex-wrap: wrap;
                                    .itembox {
                                        width: calc(50% - 10px);
                                        @include border-radius(10px);
                                        border: 2px solid $grey-light2;
                                        background-color: $white;
                                        box-sizing: border-box;
                                        cursor: pointer;
                                        @include transition(0.4s);
                                        padding: 0;
                                        margin-bottom: 15px;
                                        @include breakpoint(tablet) {
                                            width: 100%;
                                        }
                                        &.active {
                                            border-color: $purple;
                                        }
                                        label {
                                            padding: 20px;
                                            cursor: pointer;
                                        }
                                        .name {
                                            font-family: $font-semibold;
                                            font-size: 16px
                                        }
                                        .text {
                                            .price {
                                                font-family: $font-bold;
                                                font-size: 23px;
                                                color: $orange;
                                            }
                                            .txt {
                                                min-height: 63px;
                                            }
                                            .checkbox {
                                                margin-top: 12px;
                                                input {
                                                    display: none;
                                                }
                                                div {
                                                    font-family: $font-semibold;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .items-links {
                            width: 60%;
                            @include breakpoint(desktop1500) {
                                width: 65%;
                            }
                            @include breakpoint(desktop-small) {
                                width: 100%;
                            }
                            ul {
                                list-style-type: none;
                                background-color: $white;
                                @include border-radius(30px);
                                padding: 8px;
                                margin: 0;
                                display: flex;
                                @include breakpoint(large-mobile) {
                                    display: block;
                                }
                                li {
                                    width: 33.3%;
                                    display: flex;
                                    align-items: center;
                                    @include breakpoint(large-mobile) {
                                        width: 100%;
                                    }
                                    a {
                                        display: flex;
                                        height: 100%;
                                        width: 100%;
                                        @include border-radius(25px);
                                        text-align: center;
                                        text-transform: uppercase;
                                        line-height: 1.2;
                                        padding: 8px 24px;
                                        color: $purple;
                                        font-family: $font-light;
                                        @include transition(0.4s);
                                        align-items: center;
                                        align-content: center;
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        justify-items: center;
                                        @include breakpoint(desktop1500) {
                                            font-size: 14px;
                                        }
                                        @include breakpoint(tablet) {
                                            padding: 8px 12px;
                                        }
                                        span {
                                            display: block;
                                            font-family: $font-semibold;
                                            font-size: 16px;
                                            text-transform: uppercase;
                                            color: $purple;
                                            text-align: center;
                                            @include breakpoint(desktop1500) {
                                                font-size: 14px;
                                            }
                                        }
                                        &:hover {
                                            opacity: 0.7;
                                        }
                                        &.active {
                                            background-color: $orange;
                                            color: $white;
                                            span {
                                                color: $white;
                                            }
                                            &:hover {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                            .bottom-txtboxes {
                                margin-top: 35px;
                                display: flex;
                                justify-content: space-between;
                                @include breakpoint(large-mobile) {
                                    display: block;
                                }
                                >div {
                                    width: 50%;
                                    padding-right: 40px;
                                    box-sizing: border-box;
                                    font-family: $font-light;
                                    font-size: 16px;
                                    @include breakpoint(desktop1500) {
                                        font-size: 14px;
                                        padding-right: 20px;
                                    }
                                    @include breakpoint(large-mobile) {
                                        width: 100%;
                                        padding: 0;
                                    }
                                    >div {
                                        font-family: $font-semibold;
                                    }
                                    &:last-child {
                                        @include breakpoint(large-mobile) {
                                            margin-top: 15px;
                                        }
                                    }
                                }
                            }
                        }
                        &.item-langsbox {
                            .item-valuebox {
                                display: flex;
                                @include breakpoint(large-mobile) {
                                    display: block;
                                }
                                .box {
                                    &:nth-child(1), &:nth-child(3) {
                                        width: calc((100% - 50px) / 2);
                                        @include breakpoint(large-mobile) {
                                            width: 100%;
                                        }
                                    }
                                    &:nth-child(2) {
                                        width: 100px;
                                        padding-top: 5px;
                                        display: flex;
                                        justify-content: center;
                                        @include breakpoint(large-mobile) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .rightbox {
                    width: 25%;
                    padding-left: 30px;
                    box-sizing: border-box;
                    position: relative;
                    @include breakpoint(desktop1500) {
                        width: 35%;
                        padding-left: 20px;
                    }
                    @include breakpoint(desktop1400) {
                        width: 25%;
                    }
                    @include breakpoint(desktop) {
                        width: 100%;
                        padding-left: 0;
                    }
                    .rightbox-submitbox {
                        &.fixed {
                            width: 100%;
                            position: absolute;
                            right: 0;
                            @include breakpoint(desktop-small) {
                                position: relative;
                                right: auto;
                                top: auto !important;
                            }
                        }
                    }
                    .submitbox {
                        display: flex;
                        justify-content: flex-end;
                        .btn-box {
                            line-height: 1.3;
                            padding-top: 20px;
                            padding-bottom: 20px;
                        }
                    }
                    .loadingbox {
                        display: flex;
                        justify-content: center;
                    }
                    .submit-errorbox {
                        color: #dc3545;
                        margin-top: 15px;
                        text-align: center;
                    }
                    .btn-box {
                        text-align: center;
                        @include breakpoint(desktop1400) {
                            width: 100%;
                            padding: 0 20px;
                        }
                        @include breakpoint(desktop) {
                            width: auto;
                            padding: 0 40px;
                        }
                    }
                }
            }
            form {
                .select2-container--default {
                    .select2-selection--single {
                        background: none;
                        border-color: $purple;
                        .select2-selection__placeholder {
                            color: $purple;
                            font-family: $font-light;
                        }
                    }
                    .select2-selection--multiple {
                        background: none;
                        min-height: auto !important;
                        border: none;
                        @include border-radius(0);
                        border-bottom: 1px solid $purple;
                        ul {
                            padding-top: 0;
                            padding-bottom: 3px;
                            display: block;
                            @include breakpoint(desktop-small) {
                                padding-top: 5px;
                                padding-bottom: 6px;
                            }
                            >li {
                                padding: 0 !important;
                                height: auto !important;
                                input {
                                    margin: 0;
                                    font-family: $font-light;
                                    font-size: 26px;
                                    line-height: 1;
                                    color: $purple;
                                    @include breakpoint(desktop1500) {
                                        font-size: 21px;
                                    }
                                    @include breakpoint(desktop-small) {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                        .select2-selection__choice {
                            background-color: $purple !important;
                            padding: 0 5px !important;
                            font-size: 16px;
                        }
                    }
                }
                .select2-container {
                    .select2-selection--single {
                        .select2-selection__rendered {
                            padding-left: 5px;
                        }
                    }
                }
                textarea.form-control {
                    border: none;
                    @include border-radius(0);
                    font-family: $font-light;
                    font-size: 16px;
                    color: $grey;
                }
                textarea.form-control::-webkit-input-placeholder, textarea.form-control::-moz-placeholder, textarea.form-control:-ms-input-placeholder, textarea.form-control:-moz-placeholder {
                    color: $grey;
                }
            }
        }
    }
}



form {
    fieldset.form-group {
        margin-bottom: 45px;
        @include breakpoint(desktop-small) {
            margin-bottom: 30px;
        }
        @include breakpoint(large-mobile) {
            margin-bottom: 20px;
        }
    }
    input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
        border-radius: 0;
        background: none !important;
        border: none;
        border-bottom: 1px solid $grey;
        font-family: $font-light;
        font-size: 26px;
        line-height: 34px;
        height: auto;
        padding: 0 5px;
        color: $grey;
        @include breakpoint(desktop1500) {
            font-size: 21px;
        }
        @include breakpoint(desktop-small) {
            font-size: 18px;
        }
    }
    .form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control:-moz-placeholder {
        color: $grey2;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        background: none !important;
    }
    .field-checkbox {
        .lingy-checkbox-info {
            span {
                color: $purple2;
                font-size: 14px;
                @include breakpoint(large-mobile) {
                    font-size: 12px;
                    line-height: 1.3;
                    display: block;
                }
                * {
                    color: $purple2;
                    font-size: 14px;
                    @include breakpoint(large-mobile) {
                        font-size: 12px;
                    }
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.header-menu-icon {
    width: 36px;
    height: 16px;
    cursor: pointer;
    @include transition(1.2s);
    position: fixed;
    top: 32px;
    right: 30px;
    z-index: 9999;
    display: none;
    @include breakpoint(desktop-small) {
        display: block;
        top: 30px;
    }
    @include breakpoint(large-mobile) {
        right: 20px;
        top: 25px;
    }
    &:before {
        content: none;
        position:absolute;
        right: 50px;
        top: -2px;
        letter-spacing: 0.04em;
    }
    &.active {
        top: 40px;
        @include breakpoint(desktop1500) {
            top: 30px;
        }
        @include breakpoint(large-mobile) {
            top: 24px;
        }
    }
    &.sticky {
        top: 32px;
        @include breakpoint(large-mobile) {
            top: 50px;
        }
    }
    &.nav-up {
        top: -200px !important;
        position: fixed;
    }
    &> span {
        position: absolute;
        top: 50%;
        display: block;
        width: 36px;
        height: 2px;
        background-color: $purple;
        left: 0;
        right: 0;
        margin: auto;
        @include transition(0.4s);
        &:before, &:after {
            content: "";
            position: absolute;
            width: 36px;
            height: 2px;
            left: 0;
            background-color: $purple;
            @include transition(0.4s);
        }
        &:before {
            top: 7px;
            -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
        }
        &:after {
            bottom: 7px;
            -webkit-transform: translateY(1em);
            transform: translateY(1em);
        }
    }
    &.open {
        position: fixed;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        &> span{
            background-color: $white;
            width: 100%;
            &:before {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                top: 0;
                background-color: $white;
                left: 0;
            }
            &:after {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                bottom: 0;
                background-color: $white;
                left: 0;
            }
        }
    }
}

#menu-rwdbox {
    background-color: $purple;
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    z-index: 99;
    @include transition(0.7s);
    -webkit-transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition:         all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &.anim {
        -webkit-transition: all 600ms cubic-bezier(1, 0, 0, 1);
        transition:         all 600ms cubic-bezier(1, 0, 0, 1);
    }
    &.active {
        left: 0;
    }
    .contentbox {
        .topbox {
            padding: 12px 30px;
            a {
                display: block;
                img {
                    display: block;
                    height: 44px;
                }
            }
        }
        .content {
            height: calc(100vh - 70px);
            margin-top: 90px;
            padding: 0 30px;
            ul {
                display: block;
                margin: 0;
                padding: 0;
                li {
                    margin-bottom: 15px;
                    a {
                        display: block;
                        font-family: $font-semibold;
                        font-size: 32px;
                        line-height: 1;
                        color: $white;
                        padding: 0;
                        @include breakpoint(large-mobile) {
                            font-size: 24px;
                        }
                    }
                    &.languages {
                        margin-top: 50px;
                        > a {
                            display: none;
                        }
                        ul {
                            background: none;
                            display: flex;
                            position: relative;
                            top: auto;
                            left: auto;
                            right: auto;
                            li {
                                margin-right: 20px;
                                @include breakpoint(large-mobile) {
                                    margin-right: 12px;
                                }
                                a {
                                    color: $orange;
                                    text-transform: uppercase;
                                    font-size: 21px;
                                    @include breakpoint(large-mobile) {
                                        font-size: 18px;
                                    }
                                    &:hover, &:focus {
                                        color: $orange !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#dropzone-infobox {
    .dropzone-filebox {
        display: flex;
        padding: 5px 0;
        border-bottom: 1px solid $black;
        margin-bottom: 5px;
        .icon-trash {
            width: 15px;
            height: 21px;
            background: url(../img/icon-trash.svg) no-repeat center;
            background-size: cover;
            cursor: pointer;
        }
        .file-title {
            padding-left: 12px;
        }
    }
}
