$color1: #13213b;
$color2: #828995;
$white: #fff;
$black: #000;
$green: #fba311;
$grey-light2: #d7d9e0;
$grey-light3: #ced4da;
$grey: #a5a8b8;
$grey-dark: #6C757D;
$error: #cc5965;
$orange: #fba311;

@import 'app';

.text-primary {
    color: $orange !important;
}

.nav-item a .bottom-bar {
    background-color: $orange;
}

.page-item.active .page-link {
    background-color: $orange;
    border-color: $orange;
}
footer {
    .lingy {
        display: none;
    }
}
